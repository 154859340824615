import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { useIntl } from "gatsby-plugin-react-intl"
import { setSeoData } from "../services/seo.service"

const Blogs = ({
  data: { allNodeArticleBlog: nodeArticleBlog },
  pageContext,
}) => {
  const isSSR = typeof window === "undefined"
  const HeroFeaturedBlog = React.lazy(() =>
    import("../components/blog/heroFeaturedBlog")
  )
  const ListingBlog = React.lazy(() => import("../components/blog/listingBlog"))

  const intl = useIntl()

  const seoData = setSeoData({
    lang: intl.locale,
    title: intl.formatMessage({ id: "page.blogs.seo.title" }),
    field_seo: {
      description: intl.formatMessage({
        id: "page.blogs.seo.meta.description",
      }),
      title: intl.formatMessage({ id: "page.blogs.seo.title" }),
      og_description: intl.formatMessage({
        id: "page.blogs.seo.ogTags.description",
      }),
      og_image: intl.formatMessage({ id: "page.blogs.seo.ogTags.image" }),
      og_locale: intl.locale,
      og_title: intl.formatMessage({ id: "page.blogs.seo.ogTags.title" }),
      og_type: intl.formatMessage({ id: "page.blogs.seo.ogTags.type" }),
    },
  })

  let articles, featuredArticles

  if (nodeArticleBlog.nodes) {
    articles = nodeArticleBlog.nodes
    featuredArticles = nodeArticleBlog.nodes.filter(function (b) {
      return b.mea !== false
    })
  }

  return (
    <Layout pageContext={pageContext}>
      <Seo data={seoData} />
      {!isSSR && (
        <React.Suspense fallback="loading.... ">
          {featuredArticles && <HeroFeaturedBlog blogs={featuredArticles} />}
          {articles && <ListingBlog articles={articles} />}
        </React.Suspense>
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($language: String!) {
    allNodeArticleBlog(
      filter: { title: { ne: "Dummy" }, langcode: { eq: $language } }
      sort: { fields: created, order: DESC }
    ) {
      ...articleBlogListingFields
    }
  }
`

export default Blogs
